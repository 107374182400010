import React from 'react'

export default function Header() {
  return (
    <>
      <header>
        <div className='parallax-about'>
          <h2 className=' aboutSection'>About Us</h2>
          <h5 className='about'>Home / About us</h5>
        </div>
      </header>
    </>
  )
}
