import React from 'react'
import MainPage from './MainPage'

export default function index() {
    return (
        <>
            <MainPage />
        </>
    )
}
