import React from 'react'
import HeaderSection from './HeaderSection'
import OurProjects from './OurProjects'

export default function MainPage() {
    return (
        <>
            <HeaderSection />
            <OurProjects />
        </>
    )
}
