import './App.scss';
import PrivateRoutes from './Pages/PrivateRoutes';
import "../node_modules/bootstrap/dist/js/bootstrap.bundle"

function App() {
  return (
    <>
      <PrivateRoutes />
    </>
  );
}

export default App;
