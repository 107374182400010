import React from 'react'
import HomePage from "./HomePage"

export default function index() {
    return (
        <>
            <HomePage />
        </>
    )
}
