import React from 'react';
import Topbar from './Topbar';
import Navbar from './Navbar';

export default function index() {
  return (
    <div >
      <Topbar />
      <Navbar />
    </div>
  )
}
