import React from 'react'
import FooterPage from './FooterPage'

export default function index() {
    return (
        <>
            <FooterPage />
        </>
    )
}
