import React from 'react'
import "../../../Scss/contact.scss"

export default function HeaderSection() {
  return (
    <>
      <header>
        <div className='parallax-contact'>
          <h2 className=' aboutSection'>Contact Us</h2>
          <h5 className='about'>Home / Contact Us</h5>
        </div>
      </header>
    </>
  )
}
