import React from 'react'

export default function ServicesHeader() {
  return (
    <>
      <header>
        <div className='parallax'>
          <h2 className=' aboutSection'>Service Details</h2>
          <h5 className='about text-center'>Home / Service Details</h5>
        </div>
      </header>
    </>
  )
}
