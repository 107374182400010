import React from 'react'
import MianPage from './MianPage'

export default function index() {
    return (
        <>
            <MianPage />
        </>
    )
}
