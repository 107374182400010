import React from 'react';
import "../../../Scss/project.scss"

export default function HeaderSection() {
  return (
    <>
      <header>
        <div className='parallax-project'>
          <h2 className=' aboutSection'>Our Project</h2>
          <h5 className='about'>Home / Our Project</h5>
        </div>
      </header>
    </>
  )
}
