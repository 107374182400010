import React, { useEffect } from 'react';
import archecturalDesgin from "../../../Assets/Images/interiorToHaseeb.jpg"
import elevation from "../../../Assets/Images/homeOurProject.jpg"
import AOS from "aos";
import "aos/dist/aos.css"
import { useNavigate } from 'react-router-dom';


const OurProject = () => {
  const navigation = useNavigate()

  useEffect(() => {
    AOS.init({
      duration: 2000,
      once: false
    })
  }, [])

  const handleExpolre = () => {
    navigation("/project")
  }
  return (
    <section className="services-section py-5" style={{ backgroundColor: "#EDE4D9" }}>
      <div className="container">
        <h1 className='mt-5'>What We Do</h1>
        <h2 className="display-4 mt-5">Our Project</h2>
        <div className="row mt-5">
          <div className="col-md-8">
            <p className="lead">Our project combines practicality with sustainability, using eco-friendly materials and smart design to create spaces that feel good and do good for the planet. Every detail is crafted with care to ensure quality and comfort</p>
          </div>
          <div className="col-md-4 text-end">
            <button className="btn btn-dark text-light rounded-pill px-4 py-3 fs-5" onClick={handleExpolre} >Explore All</button>
          </div>
        </div>
        <div className="row mt-5 pt-5">
          <div className="col-md-6 mb-3" data-aos="flip-left">
            <div className="card border-0">
              <div className="card-body">
                <h3 className="card-title px-3 headingForInterior" >Apex Prime:</h3>
                <h3 className="card-title px-3 headingForInterior" >Interior Concept</h3>
                <div className='d-flex justify-content-around'>
                  <div style={{ color: "#C0C0C0" }}>Date : 21 january 2024</div>
                  <div style={{ color: "#C0C0C0" }}>Author :Mian Haseeb</div>
                </div>
                <img src={archecturalDesgin} className="card-img-top rounded-5 " alt="Architectural" />
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-3" data-aos="flip-right">
            <div className="card border-0">
              <div className="card-body">
                <h3 className="card-title px-3 headingForInterior" >Apex Prime:</h3>
                <h3 className="card-title px-3 headingForInterior" >Elevation Concept</h3>
                <div className='d-flex justify-content-around'>
                  <div style={{ color: "#C0C0C0" }}>Date : 21 March 2023</div>
                  <div style={{ color: "#C0C0C0" }}>Author : Mian Haseeb</div>
                </div>
                <img src={elevation} className="card-img-top rounded-5 elevation" alt="Architectural" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurProject;
